// Generated by Framer (21ec140)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["sefa7mQgB", "bzvcsvugm", "Nvx3qCOHN"];

const serializationHash = "framer-DU9Hf"

const variantClassNames = {bzvcsvugm: "framer-v-z9ddsl", Nvx3qCOHN: "framer-v-1nxepu9", sefa7mQgB: "framer-v-1ydmrdc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Phone Open": "Nvx3qCOHN", Desktop: "sefa7mQgB", Phone: "bzvcsvugm"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "sefa7mQgB"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "sefa7mQgB", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.nav {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1ydmrdc", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"sefa7mQgB"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "var(--token-c7018242-f854-4354-93c8-236664cb6489, rgb(0, 0, 0))", ...style}} {...addPropertyOverrides({bzvcsvugm: {"data-framer-name": "Phone"}, Nvx3qCOHN: {"data-framer-name": "Phone Open"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-DU9Hf.framer-wy0vhy, .framer-DU9Hf .framer-wy0vhy { display: block; }", ".framer-DU9Hf.framer-1ydmrdc { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 20px; height: 64px; justify-content: flex-start; overflow: hidden; padding: 0px; position: relative; width: 1200px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-DU9Hf.framer-1ydmrdc { gap: 0px; } .framer-DU9Hf.framer-1ydmrdc > * { margin: 0px; margin-left: calc(20px / 2); margin-right: calc(20px / 2); } .framer-DU9Hf.framer-1ydmrdc > :first-child { margin-left: 0px; } .framer-DU9Hf.framer-1ydmrdc > :last-child { margin-right: 0px; } }", ".framer-DU9Hf.framer-v-z9ddsl.framer-1ydmrdc { flex-direction: column; gap: 0px; width: 390px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-DU9Hf.framer-v-z9ddsl.framer-1ydmrdc { gap: 0px; } .framer-DU9Hf.framer-v-z9ddsl.framer-1ydmrdc > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-DU9Hf.framer-v-z9ddsl.framer-1ydmrdc > :first-child { margin-top: 0px; } .framer-DU9Hf.framer-v-z9ddsl.framer-1ydmrdc > :last-child { margin-bottom: 0px; } }", ".framer-DU9Hf.framer-v-1nxepu9.framer-1ydmrdc { flex-direction: column; gap: 0px; height: auto; max-height: calc(var(--framer-viewport-height, 100vh) * 1); overflow: auto; overscroll-behavior: contain; width: 390px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-DU9Hf.framer-v-1nxepu9.framer-1ydmrdc { gap: 0px; } .framer-DU9Hf.framer-v-1nxepu9.framer-1ydmrdc > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-DU9Hf.framer-v-1nxepu9.framer-1ydmrdc > :first-child { margin-top: 0px; } .framer-DU9Hf.framer-v-1nxepu9.framer-1ydmrdc > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 64
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"bzvcsvugm":{"layout":["fixed","fixed"]},"Nvx3qCOHN":{"layout":["fixed","auto"],"constraints":[null,null,null,"100vh"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framersooa1SliX: React.ComponentType<Props> = withCSS(Component, css, "framer-DU9Hf") as typeof Component;
export default Framersooa1SliX;

Framersooa1SliX.displayName = "Navigation Copy 7";

Framersooa1SliX.defaultProps = {height: 64, width: 1200};

addPropertyControls(Framersooa1SliX, {variant: {options: ["sefa7mQgB", "bzvcsvugm", "Nvx3qCOHN"], optionTitles: ["Desktop", "Phone", "Phone Open"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framersooa1SliX, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})